import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbCollapseModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { registerLocaleData } from '@angular/common';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';

import { LoginComponent } from './page/login/login.component';
import { HomeComponent } from './page/home/home.component';
import { MainLayoutComponent } from './page/main-layout/main-layout.component';
import { NavbarComponent } from './page/navbar/navbar.component';
import { NotFoundComponent } from './page/not-found/not-found.component';
import { BtnScrollComponent } from './page/btn-scroll/btn-scroll.component';

import localeCL from '@angular/common/locales/es-CL';
registerLocaleData(localeCL, 'es-CL');

import { Ng9RutModule } from 'ng9-rut';
import { ReportesConfinadosAdminComponent } from './fichas/confinados/reportes-admin/reportes-admin.component';
import { ReportesSearchComponent } from './fichas/confinados/reportes-search/reportes-search.component';
import { ReportesUpdateComponent } from './fichas/confinados/reportes-update/reportes-update.component';
import { ReportesVrfirexAdminComponent } from './fichas/vrfirex/reportes-admin/reportes-admin.component';
import { ReportesAlturaAdminComponent } from './fichas/altura/reportes-admin/reportes-admin.component';
import { ReportesIzajeAdminComponent } from './fichas/izaje/reportes-admin/reportes-admin.component';
import { CurricularComponent } from './fichas/curricular/curricular.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    MainLayoutComponent,
    NavbarComponent,
    NotFoundComponent,
    BtnScrollComponent,
    ReportesConfinadosAdminComponent,
    ReportesSearchComponent,
    ReportesUpdateComponent,
    ReportesVrfirexAdminComponent,
    ReportesAlturaAdminComponent,
    ReportesIzajeAdminComponent,
    CurricularComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    InfiniteScrollModule,
    NgbCollapseModule,
    NgbModalModule,
    Ng2GoogleChartsModule,
    FontAwesomeModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      countDuplicates: true
    }),
    NgSelectModule,
    Ng9RutModule
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'es-CL' }],
  bootstrap: [AppComponent],
  // entryComponents: [ReportesUpdateComponent]
})
export class AppModule { }