import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-curricular',
  templateUrl: './curricular.component.html',
  styleUrls: ['./curricular.component.scss']
})
export class CurricularComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
