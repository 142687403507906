import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainLayoutComponent } from './page/main-layout/main-layout.component';
import { HomeComponent } from './page/home/home.component';
import { NotFoundComponent } from './page/not-found/not-found.component';
import { ReportesConfinadosAdminComponent } from './fichas/confinados/reportes-admin/reportes-admin.component';
import { ReportesVrfirexAdminComponent } from './fichas/vrfirex/reportes-admin/reportes-admin.component';
import { ReportesAlturaAdminComponent } from './fichas/altura/reportes-admin/reportes-admin.component';
import { ReportesIzajeAdminComponent } from './fichas/izaje/reportes-admin/reportes-admin.component';
import { CurricularComponent } from './fichas/curricular/curricular.component';


const routes: Routes = [
  {
    path: '', component: MainLayoutComponent, children: [
      {
        path: '',
        component: HomeComponent
      },
      {
        path:'espacios_confinados', 
        component: ReportesConfinadosAdminComponent, 
      },
      {
        path:'vrfirex', 
        component: ReportesVrfirexAdminComponent, 
      },
      {
        path:'altura', 
        component: ReportesAlturaAdminComponent, 
      },
      {
        path:'izaje', 
        component: ReportesIzajeAdminComponent, 
      },
      {
        path:'curricular', 
        component: CurricularComponent, 
      },
      {
        path: '**',
        pathMatch: 'full',
        component: NotFoundComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
