<nav class="navbar navbar-expand-lg navbar-dark bg-primary d-flex">
    <a class="navbar-brand" routerLink="/">
      <!-- <img src="/assets/logo-arauco.png" alt="logo-arauco" width="120"> -->
    </a>
    <button class="navbar-toggler" type="button" (click)="isCollapsed=
          !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="collapse">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-start" id="navbarNav" [ngbCollapse]="!isCollapsed">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" routerLink="/vrfirex" routerLinkActive="active">VrFirex</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/altura" routerLinkActive="active">Trabajo de altura</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/izaje" routerLinkActive="active">Trabajo de izaje</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/espacios_confinados" routerLinkActive="active">Espacios Confinados</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/curricular" routerLinkActive="active">DBCurricular</a>
        </li>
      </ul>
    </div>
    <div class="collapse navbar-collapse justify-content-end" id="navbarNav" [ngbCollapse]="!isCollapsed">
  
      <ul class="navbar-nav">
        <li class="mr-2">
          <a class="text-light" style="cursor: context-menu;">Bienvenido/a {{username}}</a>
        </li>
        <li class="nav-item">
          <a class="text-light" style="cursor:pointer" (click)="logout()">Cerrar Sesión</a>
        </li>
      </ul>
    </div>
  </nav>