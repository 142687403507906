<div class="row">
    <div class="col">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Busqueda Avanzada</h4>
                <form #inscripcionForm="ngForm" (ngSubmit)="submit()">
                    <div class="form-row">
                        <div class="form-group col-xl-3 col-md-6 col-xs-12">
                            <label for="rutInput">DNI</label>
                            <input type="text" class="form-control" [(ngModel)]="model.rut" name="rutInput" validateRut
                                formatRut placeholder="Ingrese rut ...">
                        </div>
                    </div>
                    <div class="float-right">
                        <button type="submit" class="btn btn-primary">Buscar</button>
                        <button type="reset" class="btn btn-secondary ml-2" (click)="clear.emit()">Limpiar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- <pre>{{model|json}}</pre> -->